import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import '../components/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const Links = ({ children}) => {
  const articles = useStaticQuery(graphql`
    {
      allPrismicNewsLink {
        edges {
          node {
            id
            data {
              article_title
              publication
              author
              short_descriptor
              link {
                target
                url
              }
              excerpt {
                html
                text
              }
              preview_image {
                url
              }
            }
          }
        }
      }
    }
  `)

    const links = articles.allPrismicNewsLink.edges.map((item, key) => {
      // debugger
      const data = item.node.data
        return(

          <OuterContainer>
            <PreviewImage><Background  bg= {data.preview_image.url} className="imageBg"/></PreviewImage>
            <PressCard>
              <h2>
                {data.article_title}
              </h2>
              <h3> {data.publication} &#8226; {data.author}</h3>
              
              <div dangerouslySetInnerHTML={{ __html: data.excerpt.text }}/>
              <ReadMore href={data.link.url}>Read More</ReadMore>
            </PressCard>

          </OuterContainer>
            // <LinkItem href={"/" + item.node.uid}>
            //   <LinkItemTop>
            //     <Title>{data.title}</Title>
            //     <Background  bg= {data.link_background.url} className="imageBg"/>
            //   </LinkItemTop>
            //   <LinkItemBottom>
            //     {data.link_caption.text}
            //   </LinkItemBottom>
            // </LinkItem>
          
          )
        })

    return  (
      <>
        
        
          {links}


      </>
    )
  }



function NewsPage() {

    return(
      <Layout>
        {/* <FullWidth/> */}
        <SEO title="In the Press" />
        <Intro/>

        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-12 offset-sm-0 offset-md-0" >
                  <PressLinks>
                    <h2>In the Press</h2>
                    
                    <Links/>
                  </PressLinks>
                </div>
            </div>
        </div>




        
      </Layout>
    )
}

const OuterContainer = styled.div`
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 2px 12.5px -2.5px, rgba(0, 0, 0, 0.2) 0px 2px 7.5px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(222, 222, 222);
  border-image: initial;
  border-radius: 8px;
  margin-bottom: 2rem;
  overflow: hidden;

  @media (max-width: 768px){
    flex-direction: column;
  }
`
const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  /*background-color: blue;*/
  background-image: url(${props => props.bg});
  background-size: cover;
  z-index: 0;
  @media (max-width: 768px){
    height: 20rem;
    position: relative;
  }
`

const PreviewImage = styled.div`
  flex: 1;
  position: relative;

  @media (max-width: 768px){
    height: 20rem;
    flex: 0;
  }
`

const ReadMore = styled.a`
  align-self: flex-end;
`

const  PressLinks = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
  /*margin-right: 30px;*/


  hr {
    border: none;
    height: 1px;
    color: #E8E8E8;
    background-color: #E8E8E8;

  }

  p {
    color: "#525f7f";
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 400;
  }

  h1 {
    color: #32325d;
    margin-bottom: .5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    color: #525f7f;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
`


const PressCard = styled.div`
 
  padding: 1rem;

  display: flex;
  flex: 3;
  flex-direction: column;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #404452;
    margin-bottom: .5rem;
  }

  h3 {
    font-size: 1rem;
    color: #404452;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  p {
    font-style: italic;
    color: #404452;
  }
`



export default NewsPage
